import React from 'react'

import { Divider, Table } from 'semantic-ui-react'

import { formatDate } from '../../lib/utils/date'
import { Application } from '../../modules/application/types'
import { buildMetabaseClientExplorerLink } from '../../modules/application/utils'
import { Props } from './ApplicationsTable.types'

import './ApplicationsTable.css'

const ApplicationsTable = ({
  applications,
  coreApiApplications,
  isLoading,
}: Props) => {
  const clientApplicationsById: Record<string, Application | undefined> = {}

  for (const clientApplication of coreApiApplications || []) {
    clientApplicationsById[clientApplication.id] = clientApplication
  }

  const count = (applications || []).length
  return (
    <>
      <Divider />
      <h4>Applications</h4>
      <p>Total: {isLoading ? '...' : count}</p>
      {count > 0 && (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Client Id</Table.HeaderCell>
              <Table.HeaderCell>Environment</Table.HeaderCell>
              <Table.HeaderCell>Items</Table.HeaderCell>
              <Table.HeaderCell>Last Connection</Table.HeaderCell>
              <Table.HeaderCell>Enabled</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Updated At</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(applications || []).map((application) => {
              const {
                id,
                name,
                clientId,
                enabled,
                createdAt,
                updatedAt,
                environment,
              } = application

              const coreApiApplication = clientApplicationsById[clientId]

              const usage = coreApiApplication ? (
                <>
                  {`Active: ${coreApiApplication.success || 0}`}
                  <br />
                  {`Errors: ${coreApiApplication.errors || 0}`}
                </>
              ) : (
                'N/A'
              )

              const lastConnectionAt = coreApiApplication?.lastUpdate
                ? formatDate(coreApiApplication.lastUpdate)
                : 'N/A'

              return (
                <Table.Row key={id} data-id={id}>
                  <Table.Cell className={'title'}>{name}</Table.Cell>
                  <Table.Cell>
                    <a
                      className={'link'}
                      href={buildMetabaseClientExplorerLink(clientId)}
                    >
                      {clientId}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{environment}</Table.Cell>
                  <Table.Cell>{usage}</Table.Cell>
                  <Table.Cell>{lastConnectionAt}</Table.Cell>
                  <Table.Cell>{String(enabled)}</Table.Cell>
                  <Table.Cell>{formatDate(createdAt)}</Table.Cell>
                  <Table.Cell>{formatDate(updatedAt)}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}
    </>
  )
}

export default React.memo(ApplicationsTable)
