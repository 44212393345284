const { REACT_APP_AUTH_API_URL: apiBaseUrl } = process.env

if (apiBaseUrl === undefined || apiBaseUrl.length < 4) {
  throw new Error('Must define REACT_APP_AUTH_API_URL env var as a valid URL')
}

export type UrlsMap = {
  // Activity
  GET_ACTIVITY: string

  // Applications
  GET_APPLICATIONS: string
  GET_APPLICATION: string
  POST_APPLICATION: string
  PATCH_APPLICATION: string
  DELETE_APPLICATION: string

  // Application Share Link
  GET_APPLICATIONS_SHARE_LINKS: string
  POST_APPLICATION_SHARE_LINK: string
  PATCH_APPLICATION_SHARE_LINK: string

  // Stats
  GET_STATS: string

  // Teams
  GET_TEAMS: string
  POST_TEAM: string
  PATCH_TEAM: string
  DELETE_TEAM: string
  POST_TEAM_MEMBER: string
  PATCH_TEAM_MEMBER: string
  DELETE_TEAM_MEMBER: string
  PATCH_TEAM_INVITATION: string
  DELETE_TEAM_INVITATION: string

  // User
  GET_USERS: string
  POST_VERIFICATION_EMAIL: string

  // Customization
  GET_CUSTOMIZATION: string
  PATCH_CUSTOMIZATION: string
}

export const urls: UrlsMap = {
  // Activity
  GET_ACTIVITY: `${apiBaseUrl}/activity`,

  // Applications
  GET_APPLICATIONS: `${apiBaseUrl}/applications`,
  GET_APPLICATION: `${apiBaseUrl}/applications/:id`,
  POST_APPLICATION: `${apiBaseUrl}/applications`,
  PATCH_APPLICATION: `${apiBaseUrl}/applications/:id`,
  DELETE_APPLICATION: `${apiBaseUrl}/applications/:id`,

  // Application Share Link
  GET_APPLICATIONS_SHARE_LINKS: `${apiBaseUrl}/shareLink`,
  POST_APPLICATION_SHARE_LINK: `${apiBaseUrl}/applications/:id/shareLink`,
  PATCH_APPLICATION_SHARE_LINK: `${apiBaseUrl}/applications/:id/shareLink`,

  // Stats
  GET_STATS: `${apiBaseUrl}/stats`,

  // Teams
  GET_TEAMS: `${apiBaseUrl}/teams`,
  POST_TEAM: `${apiBaseUrl}/teams`,
  PATCH_TEAM: `${apiBaseUrl}/teams/:id`,
  DELETE_TEAM: `${apiBaseUrl}/teams/:id`,
  POST_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members`,
  PATCH_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members/:memberId`,
  DELETE_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members/:memberId`,
  PATCH_TEAM_INVITATION: `${apiBaseUrl}/teams/:id/invitations/:invitationId`,
  DELETE_TEAM_INVITATION: `${apiBaseUrl}/teams/:id/invitations/:invitationId`,

  // User
  GET_USERS: `${apiBaseUrl}/users`,
  POST_VERIFICATION_EMAIL: `${apiBaseUrl}/users/:userId/verification-email`,

  // Customization
  GET_CUSTOMIZATION: `${apiBaseUrl}/connect/customization`,
  PATCH_CUSTOMIZATION: `${apiBaseUrl}/connect/customization`,
}
