import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { accessReducer as access } from './access/reducer'
import { applicationReducer as application } from './application/reducer'
import { authReducer as auth } from './auth/reducer'
import { connectorReducer as connector } from './connector/reducer'
import { customizationReducer as customization } from './customization/reducer'
import { incidentReducer as incident } from './incident/reducer'
import { itemReducer as item } from './item/reducer'
import { notificationReducer as notification } from './notification/reducer'
import { teamReducer as team } from './team/reducer'
import { userReducer as user } from './user/reducer'

export const createRootReducer = (history: History) =>
  combineReducers({
    auth,
    application,
    access,
    connector,
    customization,
    incident,
    item,
    notification,
    router: connectRouter(history),
    team,
    user,
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
