import { all } from 'redux-saga/effects'

import { accessSaga } from './access/sagas'
import { analyticsSaga } from './analytics/sagas'
import { applicationSaga } from './application/sagas'
import { authSaga } from './auth/sagas'
import { connectorSaga } from './connector/sagas'
import { customizationSaga } from './customization/sagas'
import { incidentSaga } from './incident/sagas'
import { itemSaga } from './item/sagas'
import { notificationSaga } from './notification/sagas'
import { teamSaga } from './team/sagas'
import { userSaga } from './user/sagas'

export function* rootSaga() {
  yield all([
    analyticsSaga(),
    authSaga(),
    applicationSaga(),
    accessSaga(),
    connectorSaga(),
    customizationSaga(),
    incidentSaga(),
    itemSaga(),
    notificationSaga(),
    teamSaga(),
    userSaga(),
  ])
}
