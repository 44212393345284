import { connect } from 'react-redux'

import { FETCH_AUTH_API_APPLICATIONS_REQUEST } from '../../modules/application/actions'
import {
  getAuthApiApplications,
  getAuthApiApplicationsByUser,
  getCoreApiApplications,
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import ApplicationsTable from './ApplicationsTable'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ApplicationsTable.types'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  const { user, team } = ownProps

  return {
    applications: user
      ? // filter by specified user
        getAuthApiApplicationsByUser(state, user)
      : team
      ? // filter by specified team
        team.applications
      : // no filtering, display all
        getAuthApiApplications(state),

    coreApiApplications: getCoreApiApplications(state),
    error: getError(state),
    isLoading: isLoadingType(
      getLoading(state),
      FETCH_AUTH_API_APPLICATIONS_REQUEST,
    ),
  }
}

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ApplicationsTable)
