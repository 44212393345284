import { AxiosResponse } from 'axios'
import { PageResponse } from 'pluggy-js'

import {
  AuthApiApplication,
  CreateAuthApiApplicationRequest,
} from '../../../modules/application/types'
import { BaseService } from './BaseService'

export class ApplicationService extends BaseService {
  async getApplications(
    teamId?: string,
    search?: string,
  ): Promise<AxiosResponse<PageResponse<AuthApiApplication[]>>> {
    return await this.getServiceInstance().get(this.urls.GET_APPLICATIONS, {
      params: { teamId, search, pageSize: 500 },
    })
  }

  async createApplication(
    createApplicationFields: CreateAuthApiApplicationRequest,
  ): Promise<AxiosResponse<AuthApiApplication>> {
    return await this.getServiceInstance().post(
      this.urls.POST_APPLICATION,
      createApplicationFields,
    )
  }
}
